class TaxPayerMarket {
  type;
  typeName;

  ref;
  name;
  description;

  invoiceTypeName;
  invoiceTypeAmount;

  sign;
  nif;
  address;

  activity;
  activityStartedAt;
  activityEndedAt;
  lastPaymentPeriod;

  boxQuantity;
  boxRefs;

  cantonCode;
  cantonName;

  marketLatitude;
  marketLongitude;
  longitude;
  latitude;

  agentId;
  agentName;

  taxPayerMarketRef;

  taxPayer;

  constructor(market) {
    Object.assign(this, market);
  }

  static fromJson(market, taxPayer) {
    return new TaxPayerMarket({
      ...taxPayer,
      type: "market",
      typeName: "Marché",
      ref: market["ref"],
      name: market["name"],
      description: market["description"],
      sign: market["sign"],
      nif: market["nif"],
      address: market["address"],
      activity: market["activity"],
      activityStartedAt: market["activity_started_at"],
      activityEndedAt: market["activity_ended_at"],
      activityEnded: !!market["activity_ended_at"],
      boxQuantity: market["box_quantity"],
      boxRefs: market["box_refs"],
      cantonCode: market["canton_code"],
      cantonName: market["canton_name"],
      invoiceTypeAmount: market["invoice_type_amount"],
      invoiceTypeName: market["invoice_type_name"],
      marketLatitude: market["market_latitude"],
      marketLongitude: market["market_longitude"],
      lastPaymentPeriod: market["last_payment_period"],
      longitude: market["longitude"],
      latitude: market["latitude"],
      agentId: market["agent_id"],
      agentName: market["agent_name"],
      taxPayerMarketRef: market["tax_payer_market_ref"],
      taxPayer: taxPayer,
    });
  }

  getSearchSlug(additionalValues = []) {
    return [
      ...additionalValues,
      this.type,
      this.typeName,
      this.ref,
      this.name,
      this.description,
      this.invoiceTypeName,
      this.invoiceTypeAmount,
      this.sign,
      this.nif,
      this.address,
      this.activity,
      this.activityStartedAt,
      this.activityEndedAt ?? "",
      this.lastPaymentPeriod,
      this.boxQuantity,
      this.boxRefs,
      this.cantonCode,
      this.cantonName,
      this.marketLatitude,
      this.marketLongitude,
      this.longitude,
      this.latitude,
      this.agentId,
      this.agentName,
      this.taxPayerMarketRef,
      this.taxPayer.ref,
      this.taxPayer.nif,
      this.taxPayer.phone,
      this.taxPayer.normalizedPhone,
      this.taxPayer.nickName,
      this.taxPayer.firstName,
      this.taxPayer.lastName,
      this.taxPayer.name,
      this.taxPayer.email,
      this.taxPayer.address,
      this.taxPayer.agentId,
      this.taxPayer.agentName,
    ].join("");
  }

  populateWithData() {
    //Generate search slug that easier search.
    this.searchSlug = this.getSearchSlug();

    //Use this method in fluent mode.
    return this;
  }
}

export default TaxPayerMarket;
